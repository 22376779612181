.card {
  flex: 1 0 450px;
  padding: 1rem;
  width: fit-content;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(29, 91, 191, 0.15);
  background-color: white;
  max-width: 600px;
}
@media screen and (max-width: 800px) {
  .card {
    flex: 1 0 350px;
  }
}
