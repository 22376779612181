body {
margin:0;
 font-family: Roboto, sans-serif;
 background-color:white;
}
.margin {
 margin-bottom: 350px;
  
}
@media screen and (max-width: 1260px) {
.margin {
  margin-bottom: 0px;
 }
}
.App{
   background-image:  linear-gradient(90deg, rgba(255,255,255,0) 30vw, rgba(255,255,255,0.9) 90vw),
      linear-gradient(180deg, rgba(0,0,0,0) 20vw, rgba(255,255,255,1) 50vw),url("bg.jpeg");
    background-attachment: scroll;
    background-repeat: no-repeat;
    z-index: -10000;
      background-size: contain;
      
}