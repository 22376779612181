.menu {
  position: sticky;
  background-color: white;
  box-shadow: 0px 4px 12px rgba(29, 91, 191, 0.15);
  top: 0;
  margin: 0;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  z-index: 1000000;
  scroll-margin: 0;
  width: 100%;
  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;

    & > button {
      font-size: 24px;
      margin-right: 1rem;
      cursor: pointer;
    }
  }
}
@media (max-width: 768px) {
  .menu__buttons {
    & > button {
      font-size: 16px;
    }
  }
}
@media (max-width: 500px) {
  .menu__buttons {
    & > button {
      font-size: 12px;
    }
  }
}
