.camera {
  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1rem;
  }
  &__content {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;

    overflow: hidden;
  }
}
