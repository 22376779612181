.section {
  margin: 1.5rem clamp(0.5rem, calc(50vw - 750px), 100vw);
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
  align-items: stretch;
  flex-grow: 1;
  scroll-margin-top: 150px;
}
