.status {
  flex-grow: 1;
  text-align: center;
  height: 360px;
  font-size: 2rem;
  &__title {
    font-weight: bold;
  }
  &__item {
    width: 100%;
    height: 2.4rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    min-height: 400px;
    min-width: 400px;
  }
}
