.graph {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1rem;
  }
  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    min-height: 350px;
    min-width: 400px;
  }
}
.VictoryContainer {
  touch-action: auto !important;
  overflow: visible !important;
  & > svg {
    overflow: visible !important;
  }
}
